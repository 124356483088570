import * as React from 'react';
import {
    AppState,
    ConfigureAppParameters,
    JiraProject,
    ProjectValidation,
    JiraProjectList,
    JiraIssueType
} from "./interfaces";
import {RouteComponentProps} from "react-router-dom";
import Form from "@amzn/awsui-components-react/polaris/form";
import Container from "@amzn/awsui-components-react/polaris/container";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Button from "@amzn/awsui-components-react/polaris/button";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import Select from "@amzn/awsui-components-react/polaris/select";
import AppConfigJiraClient from "./service";
import {Link, SelectProps} from "@amzn/awsui-components-react-v3";
import {
    isLocalDevelopment,
    REFRESH_TRY_AGAIN_MESSAGE,
    STORY_ISSUE_TYPE,
    SUPPORTED_REGION_CODES,
    UNAUTHORIZED_JWT
} from "./utils";

const awsRegions = require('aws-regions')

const PROJECT_MAX_RESULTS: number = 100;

export default class App extends React.Component<RouteComponentProps<any>, AppState> {
    appConfigJiraClient: AppConfigJiraClient
    regionNames: SelectProps.Options;
    constructor(props: RouteComponentProps<any>) {
        super(props);
        const jwtToken:string = new URLSearchParams(this.props.location.search).get('jwt') ?? '';
        const jwtTokenSet = jwtToken !== '';
        this.state = {
            jwtToken: jwtToken, //TODO: discuss whether we want to use jwtToken from query string or just simply use jwtFromAtlassian directly
            jwtTokenSet: jwtTokenSet,
            accessKeyId: '',
            secretKeyId: '',
            sessionToken: undefined,
            appId: '',
            configurationProfileId: '',
            region: null,
            submittingForm: false,
            formSubmitted: false,
            jiraProject: null,
            jwtTokenFromAtlassian: UNAUTHORIZED_JWT,
            projectValidationStatus: ProjectValidation.NOT_ATTEMPTED,
            jiraProjectOptions: [],
            issueTypeOptions: [],
            issueType: null,
            isLoadingProjects: true,
            projectsStartAt: 0,
            allProjectsLoaded: false,
        }
        const domainName = !isLocalDevelopment() ? window.location.host : 'appconfig-jira-integration-beta.us-east-1.amazonaws.com';
        this.regionNames = awsRegions.list(true).filter((region: any) => SUPPORTED_REGION_CODES.has(region.code)).map((region: any) => {
            return {
                label: `${region.code} (${region.name})`,
                value: region.code
            };
        });
        this.appConfigJiraClient = new AppConfigJiraClient(domainName);
        this.testIfSubmitDisabled = this.testIfSubmitDisabled.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.resetResponse = this.resetResponse.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
    }

    componentDidMount() {
        const script = document.createElement("script")
        //load all.js script
        script.async = true
        script.src = "https://connect-cdn.atl-paas.net/all.js"
        document.body.appendChild(script)
        // @ts-ignore
        //the AP property is set by loading script from "https://connect-cdn.atl-paas.net/all.js", which we have to do to render UI
        // https://developer.atlassian.com/cloud/jira/platform/about-the-connect-javascript-api/
        // ts-ignore needed because typescript is not happy with window.AP
        this.getAllProjects(this.state.projectsStartAt);
        if (!isLocalDevelopment()) {
            this.getJWTTokenFromAtlassianJSAPI()
        }
    }

    componentDidUpdate(_: RouteComponentProps<any>, prevState: AppState) {
        if(!this.state.allProjectsLoaded && prevState.projectsStartAt !== this.state.projectsStartAt)  {
            this.getAllProjects(this.state.projectsStartAt);
        }
    }

    testIfSubmitDisabled() {
        const {appId, accessKeyId, secretKeyId, configurationProfileId, jiraProject, region, issueType} = this.state;
        return !this.state.jwtTokenSet ||
            !this.isEveryElementDefinedDefined([appId, accessKeyId, secretKeyId, configurationProfileId, jiraProject, region, issueType]);
    }

    isEveryElementDefinedDefined(toTest: any[]) {
        return toTest.every(this.isDefined);
    }

    isDefined(toTest?: any) {
        return (toTest !== null) && (toTest !== undefined) && (toTest !== '');
    }

    handleStateChange(stateKey: string, value: string) {
        const key = stateKey as keyof AppState;
        const newState = { [key]: value }
        this.setState(newState);
    }

    handleRegionChange(region: SelectProps.Option) {
        this.setState(() => {
            return {
                region: region
            };
        });
    }

    handleJiraProjectChange = async (project: SelectProps.Option) => {
        this.setState({jiraProject: project}, () => {
            this.loadIssueTypes()
        })
    }

    getJWTTokenFromAtlassianJSAPI = () => {
        window.AP.context && window.AP.context.getToken((response: any) => {
            if (response) {
                this.setState({
                    jwtTokenFromAtlassian: response
                })
            }
        })
    }

    handleProjectValidationError = (): void => {
        this.setState({
            jiraProject: null,
            projectValidationStatus: ProjectValidation.INVALID_PROJECT
        })
    }

    getAllProjects = async (startAt: number) => {
            if (!isLocalDevelopment()) {
                window.AP.request(`/rest/api/latest/project/search?startAt=${startAt}&maxResults=${PROJECT_MAX_RESULTS}`).then((response: any) => {
                    const responseJSON: JiraProjectList = JSON.parse(response.body)
                    const receivedJiraProjects: JiraProject[] = responseJSON.values;
                    const newStartAt = startAt + receivedJiraProjects.length;
                    const currentJiraProjectOptions = this.state.jiraProjectOptions
                    const receivedJiraProjectOptions: SelectProps.Options = receivedJiraProjects.map(project => {
                        return {
                            label: project.name,
                            value: project.id,
                            description: project.key,
                            iconName: 'folder'
                        }
                    });
                    const updatedJiraProjectOptions: SelectProps.Options = currentJiraProjectOptions.concat(receivedJiraProjectOptions)
                    this.setState({
                        jiraProjectOptions: updatedJiraProjectOptions,
                        isLoadingProjects: false,
                        allProjectsLoaded: responseJSON.isLast,
                        projectsStartAt: newStartAt
                    });
                }).catch(() => {
                    this.handleProjectValidationError()
                })
            } else {
                const localDevelopmentProjectOptions: SelectProps.Options = [1,2,3,4].map(i => {
                    return {
                        label: `test project ${i}`,
                        value: `${i}`,
                        description: `Test project ${i} for local dev`,
                        iconName: 'folder'
                    }
                });
                const newStartAt = startAt + localDevelopmentProjectOptions.length;
                this.setState(
                    {
                        jiraProjectOptions: localDevelopmentProjectOptions,
                        allProjectsLoaded: true,
                        projectsStartAt: newStartAt,
                        isLoadingProjects: false
                    }
                )
            }
    }

    handleIssueTypesLoadingError = (): void => {
        alert(`Error loading issue types for project ${this.state.jiraProject?.label}`)
        this.setState({
            issueTypeOptions: [],
            issueType: null
        })
    }

    handleLocalDevelopmentIssueFetching = (): void => {
        if (this.state.jiraProject?.value !== '4') { //let the last selected project locally help us mock out failure response
            const localDevIssueTypes: SelectProps.Options = [1,2,3].map(i => {
                return {
                    label: `Test Issue Type ${i}`,
                    value: `${i}`,
                    description: 'Test description for task name'
                }
            })
            this.setState({
                issueTypeOptions: localDevIssueTypes
            })
        } else {
            this.handleIssueTypesLoadingError()
        }
    }

    loadIssueTypes = async () => {
        const selectedProjectId = this.state.jiraProject?.value
        if (!selectedProjectId) return
        if (!isLocalDevelopment()) {
            //fetch issues with heirarchy level 0 (base type). https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issue-types/#api-rest-api-3-issuetype-project-get
            // subtsasks are -1, and we dont want to do subtasks (for now) as that requires additionally choosing a parent issue. epics are level 1, and we dont want to use epics either.
            window.AP.request(`/rest/api/latest/issuetype/project?projectId=${selectedProjectId}&level=0`).then((response: any) => {
                const issueTypes: JiraIssueType[] = JSON.parse(response.body)
                const options: SelectProps.Options = issueTypes.map((issueType: JiraIssueType) => {
                    return {
                        label: issueType.name,
                        value: issueType.id,
                        description: issueType.description
                    }
                })

                //To avoid too many user clicks, we default to selecting "Story" as the default issue type.
                const storyType: SelectProps.Option = options.find(o => o.label === STORY_ISSUE_TYPE) as SelectProps.Option
                const preSelectedOption: SelectProps.Option | null = storyType ? storyType : null
                this.setState({
                    issueTypeOptions: options,
                    issueType: preSelectedOption
                })
            }).catch(() => {
                this.handleIssueTypesLoadingError()
            })
        } else {
            this.handleLocalDevelopmentIssueFetching()
        }
    }

    handleIssueTypeSelection = (selectedIssueType: SelectProps.Option): void => {
        this.setState({
            issueType: selectedIssueType
        })

    }

    async postConfigureApp() {
        const region: string = this.state.region ? (this.state.region.value?? '') : '';
        const parameters : ConfigureAppParameters = {
            accessKeyId: this.state.accessKeyId,
            secretKeyId: this.state.secretKeyId,
            region: region,
            appId: this.state.appId,
            configurationProfileId: this.state.configurationProfileId,
            projectId: this.state.jiraProject ? (this.state.jiraProject.value?? '') :  '',
            issueTypeId: this.state.issueType ? this.state.issueType!.value! : '' //value will always be defined
        }
        if (this.isDefined(this.state.sessionToken)) {
            parameters.sessionToken = this.state.sessionToken;
        }
        return await this.appConfigJiraClient.postConfigureApp(parameters, window.location.search);
    }

    async submitForm() {
        const updatedState = {submittingForm: true} as Pick<AppState, keyof AppState>;
        this.setState( updatedState,
            async () => this.postConfigureApp()
                .then((response) => {
                    const newState = {submittingForm: false, formSubmitted: true, responseCode: response.status, responseMessage: response.content} as Pick<AppState, keyof AppState>;
                    this.setState(newState);
        }));
    }
    resetResponse() {
        this.setState(() => {
            return {
                formSubmitted: false,
            };
        });
    }

    render() {
        const { responseCode, responseMessage, formSubmitted, submittingForm, jwtTokenFromAtlassian } = this.state;
        const message: string = responseMessage?? '';
        const shouldDisplayFlash: boolean = formSubmitted && (responseMessage !== undefined) && (responseCode !== undefined) && !submittingForm;
        const authorizedToViewConfigureForm = jwtTokenFromAtlassian !== UNAUTHORIZED_JWT || isLocalDevelopment();
        return (
            <AppLayout
    navigationHide={true}
    toolsHide={true}
    content={
        authorizedToViewConfigureForm ? (
        <div id="authorized-page-container">
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="primary" disabled={this.testIfSubmitDisabled()} formAction={'submit'} loading={this.state.submittingForm} onClick={(detail) => this.submitForm()}>Submit</Button>
                    </SpaceBetween>
                }
                header={<Header variant="h1">AWS AppConfig Feature Flags Jira Configuration</Header>}
            >
                <SpaceBetween direction="vertical" size="l">
                    <Container
                        header={
                            <Header variant="h2"
                                description={
                                    <span>AWS AppConfig Feature Flags can be connected to a specific project in Jira, and a new Jira ticket will be created for each feature flag.&nbsp;
                                        You specify an AWS AppConfig Configuration Profile, which is a grouping of multiple flags, or could just be a single flag.&nbsp;
                                        You will also need to provide AWS Credentials for Jira to connect to AWS AppConfig. More information can be found&nbsp;
                                    <Link
                                      variant="info"
                                      href="https://docs.aws.amazon.com/appconfig/latest/userguide/appconfig-integration.html"
                                      target="_blank"
                                    >
                                        here.
                                    </Link>
                                </span>}
                            >
                                Configuration Details
                            </Header>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <ColumnLayout>
                                <FormField label="Jira Project">
                                    <Select
                                        selectedOption={this.state.jiraProject}
                                        onChange={({ detail }) =>
                                            this.handleJiraProjectChange(detail.selectedOption)
                                        }
                                        statusType={this.state.isLoadingProjects ? 'loading' : (this.state.allProjectsLoaded ? 'finished': 'pending')}
                                        options={this.state.jiraProjectOptions}
                                        placeholder="Choose a Jira Project"
                                        selectedAriaLabel="Selected"
                                        filteringType='auto'
                                        empty="No options"
                                    />
                                </FormField>
                            </ColumnLayout>
                            <ColumnLayout>
                                <FormField label="Issue Type">
                                    <Select
                                      selectedOption={this.state.issueType}
                                      onChange={({ detail }) =>
                                        this.handleIssueTypeSelection(detail.selectedOption)
                                      }
                                      disabled={!this.isDefined(this.state.jiraProject)}
                                      options={this.state.issueTypeOptions}
                                      placeholder="Choose a Jira Issue Type"
                                      selectedAriaLabel="Selected Jira Issue Type"
                                      empty="No issue types available for this project"
                                    />
                                </FormField>
                            </ColumnLayout>
                            <ColumnLayout>
                                <FormField label="AWS Region">
                                    <Select
                                        selectedOption={this.state.region}
                                        onChange={({ detail }) =>
                                            this.handleRegionChange(detail.selectedOption)
                                        }
                                        options={this.regionNames}
                                        placeholder="Choose an AWS region"
                                        selectedAriaLabel="Selected"
                                        filteringType='auto'
                                        empty="No options"
                                    />
                                </FormField>
                                <FormField label="Application Name or Id">
                                    <Input
                                        onChange={({detail}) => this.handleStateChange('appId', detail.value)}
                                        value={this.state.appId}
                                    />
                                </FormField>
                                <FormField label="Configuration Profile Name or Id">
                                    <Input
                                        onChange={({detail}) => this.handleStateChange('configurationProfileId', detail.value)}
                                        value={this.state.configurationProfileId}
                                    />
                                </FormField>
                            </ColumnLayout>
                        </SpaceBetween>
                    </Container>
                    <Container
                        header={
                            <Header variant="h2"
                                    description={
                                        <span>
                                            Create a separate IAM Role within your AWS account with appconfig:ListApplications, appconfig:ListConfigurationProfiles, appconfig:ListExtensionAssociations, appconfig:CreateExtensionAssociation, appconfig:GetConfigurationProfile, and sts:GetCallerIdentity permissions.&nbsp;
                                            AWS AppConfig will use the credentials from this role to connect your Jira instance and AWS AppConfig resources. We do not store the AWS credentials you provided to us.
                                            Click&nbsp;
                                            <Link
                                              variant="info"
                                              href="https://docs.aws.amazon.com/appconfig/latest/userguide/getting-started-with-appconfig-permissions.html"
                                              target="_blank"
                                            >
                                                here
                                            </Link>
                                            &nbsp;to see how to create this role.
                                        </span>}
                                    >
                                AWS Credentials
                            </Header>
                        }
                        >
                        <ColumnLayout>
                            <FormField label="AccessKeyId">
                                <Input
                                    onChange={({detail}) => this.handleStateChange('accessKeyId', detail.value)}
                                    value={this.state.accessKeyId}
                                    type="password"
                                />
                            </FormField>
                            <FormField label="Secret Key">
                                <Input
                                    onChange={({detail}) => this.handleStateChange('secretKeyId', detail.value)}
                                    value={this.state.secretKeyId}
                                    type="password"
                                />
                            </FormField>
                            <FormField
                                label={
                                    <span>
                                          Session Token <i>- optional</i>{" "}
                                        </span>
                                }
                            >
                                <Input
                                    onChange={({detail}) => this.handleStateChange('sessionToken', detail.value)}
                                    value={this.state.sessionToken ?? ''}
                                    type="password"
                                />
                            </FormField>
                        </ColumnLayout>
                    </Container>
                    <Alert
                        visible={!this.state.jwtTokenSet}
                        dismissAriaLabel="Close alert"
                        type="error"
                        header="JWT token not set in query string"
                    >
                        You will not be able to authorize this request without a valid JWT token.
                    </Alert>
                    <Alert
                        visible={shouldDisplayFlash}
                        dismissAriaLabel="Close alert"
                        dismissible={true}
                        onDismiss={() => this.resetResponse()}
                        type={responseCode === 200 ? 'success' : 'error'}
                        header={responseCode === 200 ? 'Successfully configured app' : 'Failed to configure app'}
                    >
                        <TextContent>{`${ responseCode && responseCode >= 500 ? REFRESH_TRY_AGAIN_MESSAGE : message}`}</TextContent>
                    </Alert>
                </SpaceBetween>
            </Form>
        </div>
        ) : (
        <div id="unauthorized-alert">
            <Alert
                visible={true}
                dismissible={false}
                type="error"
                header="Unauthorized"
            >
                You are not authorized to view this page
            </Alert>
        </div>
        )
    }
    />
        );
    }
}