import * as React from 'react';
import {render} from 'react-dom';
import {BrowserRouter, Route, RouteComponentProps, Switch} from "react-router-dom";
import App from './App';

const rootElement = document.getElementById("root");
const app = <React.StrictMode>
        <div className="awsui">
                <BrowserRouter>
                        <Switch>
                                <Route path="/*" render={(props: RouteComponentProps<any>) => <App {...props}/>} />
                        </Switch>
                </BrowserRouter>
        </div>
</React.StrictMode>;

render(app, rootElement)