

export const UNAUTHORIZED_JWT = "UNAUTHORIZED"

export const REFRESH_TRY_AGAIN_MESSAGE = "Authorization token expired: Please refresh the page in your browser and try re-submitting the form within a few minutes of loading the page."

export const STORY_ISSUE_TYPE = "Story"

export const SUPPORTED_REGION_CODES = new Set([
	"us-east-2",
	"us-east-1",
	"us-west-1",
	"us-west-2",
	"ap-south-1",
	"ap-northeast-3",
	"ap-northeast-2",
	"ap-southeast-1",
	"ap-southeast-2",
	"ap-northeast-1",
	"ca-central-1",
	"eu-central-1",
	"eu-west-1",
	"eu-north-1",
	"sa-east-1"
])

export const isLocalDevelopment =(): boolean => {
	return window.location.host.includes('3000')
}

