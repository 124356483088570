import {ConfigureAppParameters, ConfigureAppResponse} from "./interfaces";

const ENDPOINT_SUFFIX = "/app/configure";
const ENDPOINT_PROTOCOL = "https://"
export default class AppConfigJiraClient {
    endpoint: string;
    constructor(endpoint: string) {
        this.endpoint = `${ENDPOINT_PROTOCOL}${endpoint}${ENDPOINT_SUFFIX}`;
    }

    async getResponseData(rawResponse: Response) {
        return {
            status: rawResponse.status,
            content: JSON.stringify(await rawResponse.json())
        };
    }

    async postConfigureApp(params: ConfigureAppParameters, queryString: string) {
        const data = JSON.stringify(params);
        const endpoint = `${this.endpoint}${queryString}`;
        const response: ConfigureAppResponse = await fetch(endpoint, {
            method: 'POST',
            mode: 'cors',
            headers: {
            'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: data

        })
        .then(response => this.getResponseData(response))
        .catch((error: Error) => {
            return {
                status: 503,
                content: error.message

            }
        });

        return response;
    }
}
