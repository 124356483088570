import {SelectProps} from "@amzn/awsui-components-react";

export enum ProjectValidation {
    VALID_PROJECT,
    NOT_ATTEMPTED,
    INVALID_PROJECT
}
export interface AppState {
    jwtToken: string;
    jwtTokenSet: boolean;
    accessKeyId: string;
    secretKeyId: string;
    sessionToken?: string;
    appId: string;
    configurationProfileId: string;
    region: SelectProps.Option | null,
    submittingForm: boolean;
    formSubmitted: boolean;
    responseCode?: number;
    responseMessage?: string;
    jwtTokenFromAtlassian: string;
    isLoadingProjects: boolean;
    jiraProject: SelectProps.Option | null;
    projectValidationStatus: ProjectValidation
    jiraProjectOptions: SelectProps.Options;
    projectsStartAt: number;
    allProjectsLoaded: boolean;
    issueType: SelectProps.Option | null
    issueTypeOptions: SelectProps.Options
    [key: string]: any;
}


export interface JiraProject {
    name: string
    id: string
    key: string
}

export interface JiraProjectList {
    self: string;
    nextPage: string;
    maxResults: number;
    startAt: number;
    isLast: boolean;
    values: JiraProject[];
    total: number;
}

export interface ConfigureAppParameters {
    accessKeyId: string;
    secretKeyId: string;
    sessionToken?: string;
    appId: string;
    configurationProfileId: string;
    projectId: string;
    region: string;
    issueTypeId: string
}

export interface ConfigureAppResponse {
    status: number;
    content: string;
}

export interface JiraIssueType {
    id: string;
    name: string;
    hierarchyLevel: number;
    description?: string
}


declare global {
    interface Window {AP: any;}
}